<template>
  <div v-if="modelValue.sub_type === subtypesList.unitIsHeld" class="text-body-2 flex flex-col gap-2">
    <div>
      <span class="text-caption mr-2 rounded-lg bg-additional-1-50 p-1 text-error-100">{{ $t('on_hold') }}</span>
      <span class="text-body-2">
        <NuxtLink
          v-if="unitNumber"
          :to="`/properties/exclusives?query=${unitNumber}&project_ids=${projectId}`"
          target="_blank"
          class="text-subhead-4 underline"
        >
          {{ unitNumber }}
        </NuxtLink>
        <span v-else class="text-subhead-4 inline-block capitalize">{{ $t('unit') }}</span>

        has been reserved by
        <span class="text-subhead-4"> {{ reservedBy }} </span>.
      </span>
    </div>
    <div class="flex flex-col gap-1">
      <template v-if="isOtherPaymentMethod">
        <span class="text-caption text-black-40">Payment method:</span>
        <p class="text-body-2">
          <span
            class="text-subhead-4"
            :class="{ ' cursor-pointer text-primary-100': showLink }"
            @click="showConfirmationPopup"
            >Add payment confirmation</span
          >
          documents on a Tokens tab
        </p>
      </template>
      <template v-else>
        <span class="text-caption text-black-40">Payment link:</span>
        <p class="text-body-2">The payment link and token information are stored on the Tokens tab.</p>
      </template>
    </div>
  </div>
  <div v-else-if="modelValue.sub_type === subtypesList.confirmed">
    Token <span class="text-subhead-4">payment confirmation was added</span> by {{ actorName }}. Review documents on a
    Tokens tab.
  </div>
  <div v-else-if="modelValue.sub_type === subtypesList.unitIsCanceled" class="flex items-start">
    <div class="text-caption mr-2 rounded-lg bg-additional-3-10 p-1 text-additional-3-100">{{ $t('canceled') }}</div>
    <div>
      The payment token was canceled by <span class="text-subhead-4"> {{ actorName }} </span>.
      <NuxtLink
        v-if="unitNumber"
        :to="`/properties/exclusives?query=${unitNumber}&project_ids=${projectId}`"
        target="_blank"
        class="text-subhead-4 underline"
      >
        Unit {{ unitNumber }}
      </NuxtLink>
      has been removed from Hold and is now available for booking.
    </div>
  </div>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core'
import type { TimelineItem } from '@/types'
import { PAYMENT_TOKEN_CODES } from '~/constants'
import type { UnitPaymentToken } from '~/types/properties'
import { useUiStore } from '~/store/ui'

const bus = useEventBus<UnitPaymentToken['id']>('showConfirmationPopup')

const subtypesList = {
  confirmed: 'payment_token:confirmed',
  unitIsHeld: 'payment_token:unit_is_held',
  unitIsCanceled: 'payment_token:unit_is_cancelled',
}

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

const uiStore = useUiStore()

const reservedBy = computed(() => props.modelValue.additional?.find((i) => i.field === 'Agent')?.value)

const unitNumber = computed(() => props.modelValue.additional?.find((i) => i.field === 'Unit number')?.value)
const projectId = computed(() => props.modelValue.additional?.find((i) => i.field === 'Project id')?.value)
const actorName = computed(() => props.modelValue.additional?.find((i) => i.field === 'Actor name')?.value)
const showLink = computed(() => uiStore.getActivePaymentTokens.includes(props.modelValue.payment_token_id!))

const isOtherPaymentMethod = computed(
  () => props.modelValue.additional?.find((i) => i.field === 'Token type')?.value === PAYMENT_TOKEN_CODES.MANUAL
)

const showConfirmationPopup = () => {
  if (showLink.value) {
    bus.emit(props.modelValue.payment_token_id)
  }
}
</script>

<style scoped lang="scss"></style>
