<template>
  <div class="relative flex flex-col gap-4">
    <div
      :class="{ hidden: modelValue.rotation === null && (authStore.getIsSalesManager || authStore.getIsAgent) }"
      class="text-body-2 flex flex-col gap-3 rounded-3xl bg-white p-6"
    >
      <h5>Leads rotation</h5>
      <div>
        <div class="mb-1 text-black-60">Mapping rule</div>
        <div>
          {{
            modelValue.rotation?.mappings?.length
              ? modelValue.rotation.mappings
                  .map((m) => m.name)
                  .flat()
                  .toString()
                  .replace(',', ', ')
              : '-'
          }}
        </div>
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Rotation box</div>
        <div
          v-if="[ROTATION_TYPES.DEFAULT, ROTATION_TYPES.CUSTOM].includes(modelValue.rotation?.type)"
          id="goToRotation"
          class="text-subhead-4 cursor-pointer underline first-letter:uppercase"
          @click="
            navigateTo(`/rotations/${modelValue.rotation.type}?query=${modelValue.rotation.name}`, {
              open: {
                target: '_blank',
              },
            })
          "
        >
          {{ modelValue.rotation.type }} box:
          {{ modelValue.rotation.name }}
        </div>
        <span v-else-if="modelValue.rotation?.type === ROTATION_TYPES.USER">
          User: {{ modelValue.rotation.mappings[0].user.name }}
        </span>
        <span v-else>-</span>
      </div>
    </div>
    <div class="flex flex-col gap-3 rounded-3xl bg-white p-6">
      <div class="flex items-center justify-between">
        <h5>Source details</h5>
        <button v-if="useHasPermissions([PERMISSIONS.CAN_EDIT_LEAD_SOURCE])" @click="showEditSourceSidePanel">
          <UiIcon name="edit" class="text-black-70" size="xs" />
        </button>
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Digital</div>
        <div>
          {{ modelValue.is_digital ? 'Yes' : 'No' }}
        </div>
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Marketer</div>
        {{ modelValue.marketer?.name || '-' }}
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Channel</div>
        <div>
          {{ modelValue.campaign?.utm_source || '-' }}
        </div>
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Source</div>
        <div>
          {{ sourceName }}
        </div>
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Campaign name</div>
        {{ modelValue.campaign?.utm_campaign || '-' }}
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Project</div>
        <div>
          <p
            v-if="modelValue.campaign?.utm_project && useGetPropertyProject(modelValue.campaign?.utm_project)"
            class="cursor-pointer text-primary-100"
            @click="
              navigateTo(`/properties/${modelValue.campaign?.utm_project}`, {
                open: {
                  target: '_blank',
                },
              })
            "
          >
            {{ useGetPropertyProject(modelValue.campaign?.utm_project) }}
          </p>
          <span v-else>{{ modelValue.campaign?.utm_project || '-' }}</span>
        </div>
      </div>
      <div class="text-body-2 relative">
        <div class="mb-1 text-black-60">Term</div>
        <span ref="termsArea" :class="{ '!h-10': isOverHeight }" class="block h-full overflow-hidden transition-all">{{
          modelValue.campaign?.utm_term || '-'
        }}</span>
        <span
          v-if="isOverHeight"
          class="cursor-pointer text-primary-100 underline"
          @click="isOverHeight = !isOverHeight"
          >more</span
        >
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Content</div>
        {{ modelValue.campaign?.utm_content || '-' }}
      </div>
      <div class="text-body-2">
        <div class="mb-1 text-black-60">Additional info</div>
        {{ modelValue.campaign?.comment || '-' }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SIDE_PANELS } from '@/components/dynamic/maps'
import type { Lead } from '@/types'
import { useUiStore } from '@/store/ui'
import { useAuthStore } from '~/store/auth'
import { ROTATION_TYPES, CUSTOM_EVENTS, PERMISSIONS } from '~/constants'

type Props = {
  modelValue: Lead
}

const props = defineProps<Props>()

const uiStore = useUiStore()
const authStore = useAuthStore()
const isOverHeight = ref(false)
const termsArea = ref<HTMLElement>()

const PARTNER_PORTAL_SOURCE_CODE = 'partner_portal'

const sourceName = computed(() => {
  if (props.modelValue.source.code === PARTNER_PORTAL_SOURCE_CODE && props.modelValue.partner?.id) {
    return `${props.modelValue.source?.name} - ID: ${props.modelValue.partner.id}`
  }

  return props.modelValue.source?.name
})

onMounted(() => {
  if (termsArea.value?.clientHeight !== undefined && termsArea.value?.clientHeight > 45) {
    isOverHeight.value = true
  }
})

const refreshLead = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_DATA))
}

const showEditSourceSidePanel = () => {
  uiStore.showSidePanel(
    SIDE_PANELS.LEAD_INFO_SOURCE,
    { lead: props.modelValue },
    {
      input: () => refreshLead(),
    }
  )
}
</script>

<style scoped></style>
