<template>
  <div>
    <div class="relative h-10" style="overflow: visible !important">
      <div
        id="global-search"
        ref="searchRef"
        class="absolute right-0 text-black-100 transition-width duration-100"
        :class="modelValue ? 'z-20 w-[680px]' : 'w-52'"
        @click="!modelValue ? (modelValue = true) : null"
      >
        <!-- Search -->
        <UiInputSearch
          id="global-search-field"
          v-model="filter.search_word"
          class="group !gap-1 !py-2 !text-white"
          icon-classes="!text-dm-50"
          :input-classes="`!text-white !bg-dm-90 group-[.driver-active-element]:!border-white ${
            modelValue ? '!border-white' : '!border-dm-70'
          }`"
          compact
          :lowercase="false"
          @search:debounced="onSearch($event)"
          @input="loading = true"
          @search="resetSearch()"
        />
        <div
          v-if="modelValue"
          id="search-content"
          class="mt-0.5 h-[calc(100vh-120px)] max-h-[700px] w-full overflow-hidden rounded-xl bg-white shadow"
        >
          <div class="relative flex size-full flex-col">
            <!-- Tabs -->
            <div class="relative w-full px-6 py-4 pb-2" :class="{ 'shadow-down': showOverflowShadow }">
              <UiTabsSecondary v-model="filter.tab" :tabs="tabs" @update:model-value="onChangeTab()" />
              <p class="-mt-0.5 h-0.5 w-full bg-black-10"></p>
            </div>
            <div
              class="relative flex h-[calc(100%-62px)]"
              :class="[
                showFilter ? 'w-[calc(100%-230px)]' : 'w-[calc(100%-24px)]',
                (!filter.search_word && !recentlyViewed.length) || (filter.search_word && !items.length)
                  ? 'p-6'
                  : 'pb-6 pr-3 pt-2',
              ]"
            >
              <!-- Сontent -->
              <div
                ref="contentRef"
                class="styled-scrollbar flex flex-1 overflow-y-auto overflow-x-hidden"
                @scroll="checkScroll"
              >
                <UiLoader v-if="loading" class="mx-6 mt-2" />
                <template v-else>
                  <template v-if="!filter.search_word">
                    <UiEmptyCard
                      v-if="!recentlyViewed.length"
                      id="empty-recently-viewed"
                      title="Let’s try it!"
                      description="We’ll keep your search history here to make it easier for you. Enjoy 😊"
                    />
                    <div v-if="recentlyViewed.length" class="w-full">
                      <div class="mb-2 flex items-center gap-2">
                        <p class="text-table-header pl-6 text-black-40">Recently Viewed</p>
                      </div>
                      <HeaderGlobalSearchCard
                        v-for="item in recentlyViewed"
                        :key="item.id"
                        :item="item"
                        @close="closeGlobalSearch()"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <UiEmptyCard
                      v-if="!items.length"
                      id="empty-search-results"
                      icon="no-results"
                      title="Ooops! No results"
                      description="We couldn’t find anything matching your search. Please try again with a new query"
                    />
                    <div v-if="items.length" class="w-full">
                      <div class="mb-2 flex items-center gap-2">
                        <p class="text-table-header pl-6 text-black-40">Results</p>
                        <p class="text-caption rounded-2xl bg-primary-100 px-2 py-1 text-white">{{ countUsers }}</p>
                      </div>
                      <HeaderGlobalSearchCard
                        v-for="item in items"
                        :key="item.id"
                        :item="item"
                        show-details
                        :search="filter.search_word"
                        @close="closeGlobalSearch()"
                      />
                      <UiLoader v-if="moreLoading" class="mx-6 mt-2" />
                      <UiButtonGhost
                        v-else-if="items.length < countUsers"
                        id="show-more-results"
                        class="ml-[72px] mt-2"
                        @click="showMore"
                        >See more</UiButtonGhost
                      >
                      <Transition name="fade">
                        <UiButtonBase
                          v-if="showOverflowShadow"
                          id="go_to_top"
                          icon
                          class="absolute bottom-6 right-2"
                          @click="scrollToTop"
                          ><UiIcon name="arrow-big-up"
                        /></UiButtonBase>
                      </Transition>
                    </div>
                  </template>
                </template>
              </div>
            </div>
            <!-- Filters -->
            <div
              id="search-filters"
              style="overflow: visible !important"
              class="absolute bottom-0 right-0 top-[62px] flex w-[230px] flex-col gap-6 border-l border-black-10 p-6 shadow-left transition"
              :class="showFilter ? 'translate-x-0' : 'translate-x-[206px]'"
            >
              <div>
                <p class="text-table-header mb-2 text-black-40">Last updated</p>
                <div class="flex flex-wrap gap-1 rounded-lg bg-primary-05 p-1">
                  <button
                    v-for="item in lastUpdatedItems"
                    :id="`last-updated-${item.id}`"
                    :key="item.id"
                    :class="{ '!bg-primary-10': filter.updated === item.id }"
                    class="text-body-2 grow rounded bg-white px-2 py-1.5 text-left"
                    @click="setLastUpdated(item)"
                  >
                    {{ item.text }}
                  </button>
                </div>
              </div>
              <div>
                <p class="text-table-header mb-2 text-black-40">Display only</p>
                <div class="flex flex-wrap gap-1 rounded-lg bg-primary-05 p-1">
                  <button
                    v-for="item in displayOnlyItems"
                    :id="`display-only-${item.value}`"
                    :key="item.value"
                    :class="{
                      '!bg-primary-10':
                        filter.search_fields.includes(item.value) || filter.pipeline_codes.includes(item.value),
                    }"
                    class="text-body-2 grow rounded bg-white px-2 py-1.5 text-left"
                    @click="setDisplayOnly(item)"
                  >
                    {{ item.text }}
                  </button>
                </div>
              </div>
              <button
                id="toggle-search-filter"
                class="absolute -left-3 top-2 z-10 rounded-full bg-primary-100 p-1 shadow"
                @click="showFilter = !showFilter"
              >
                <UiIcon name="arrow-small-right" class="!size-4 text-white" :class="{ 'rotate-180': !showFilter }" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modelValue" class="absolute inset-0 z-10 bg-black-100/10 backdrop-blur-sm"></div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { subDays, subMonths, startOfDay, endOfDay } from 'date-fns'
import { useAuthStore } from '~/store/auth'
import type { Tab, InputItem, GlobalSearchFilters, GlobalSearchItem } from '@/types'
import globalSearchMock from '~/mocks/globalSearch'
import { useUiStore } from '~/store/ui'

const authStore = useAuthStore()
const uiStore = useUiStore()

const modelValue = ref(false)
const searchRef = ref<HTMLElement>()

const loading = ref(false)
const moreLoading = ref(false)
const showOverflowShadow = ref(false)
const contentRef = ref<HTMLElement>()

const showFilter = ref(false)
const filter = ref<GlobalSearchFilters>({
  search_word: '',
  tab: 'all',
  updated: '',
  updated_at_from: null,
  updated_at_to: null,
  search_fields: [''],
  pipeline_codes: [],
})
const isTour = ref(false)

const pagination = ref({
  page: 1,
  limit: 10,
})

const countUsers = ref(0)

const tabs: Tab[] = [
  { id: 'all', text: 'All' },
  { id: 'leads', text: 'Leads' },
  { id: 'deals', text: 'Deals' },
]

const now = new Date()
const formatToStart = startOfDay(now)
const formatToEnd = endOfDay(now)
const lastUpdatedItems = ref<InputItem[]>([
  { text: 'Any time', id: '', value: '' },
  { text: 'Today', id: 'today', value: [formatToStart.toISOString(), formatToEnd.toISOString()] },
  {
    text: 'Yesterday',
    id: 'yesterday',
    value: [subDays(formatToStart, 1).toISOString(), subDays(formatToEnd, 1).toISOString()],
  },
  {
    text: 'Last 7 days',
    id: 'last_7_days',
    value: [subDays(formatToStart, 7).toISOString(), formatToEnd.toISOString()],
  },
  {
    text: 'Last month',
    id: 'last_month',
    value: [subMonths(formatToStart, 1).toISOString(), formatToEnd.toISOString()],
  },
  {
    text: 'Last 3 months',
    id: 'last_3_months',
    value: [subMonths(formatToStart, 3).toISOString(), formatToEnd.toISOString()],
  },
])

const displayOnlyItems = ref<InputItem[]>([
  { text: 'Anywhere', value: '', id: 'search_fields' },
  { text: 'Notes', value: 'notes', id: 'search_fields' },
  { text: 'Info and contacts', value: 'contacts', id: 'search_fields' },
  { text: 'Pipeline', value: 'sales', id: 'pipeline_codes' },
  { text: 'Archived', value: 'archive', id: 'pipeline_codes' },
])

if (!authStore.getIsAgent) {
  displayOnlyItems.value.push({ text: 'Pool', value: 'pool', id: 'pipeline_codes' })
}

const recentlyViewed = computed(() => {
  return authStore.getUser?.search_history?.slice().reverse()
})

const items = ref<GlobalSearchItem[]>([])

onClickOutside(searchRef, () => {
  if (!isTour.value) closeGlobalSearch()
})

const closeGlobalSearch = () => {
  if (modelValue.value) modelValue.value = false
}
const checkScroll = () => {
  showOverflowShadow.value = Number(contentRef.value?.scrollTop) > 0
}
const scrollToTop = () => {
  contentRef.value?.scrollTo({ top: 0, behavior: 'smooth' })
}

const onSearch = (event: string) => {
  if (isTour.value) return
  if (!event) {
    resetSearch()
    return
  }
  pagination.value.page = 1
  filter.value.search_word = event
  getSearchedItems()
}

onMounted(async () => {
  await nextTick()
  useMittListen('tour:globalSearch', () => {
    isTour.value = true
  })
  useMittListen('tour:setGlobalSearchMock', () => {
    isTour.value = true
    filter.value.search_word = 'Marina'
    setMockData()
    modelValue.value = true
  })
  useMittListen('tour:clearGlobalSearchMock', () => {
    resetSearch()
    isTour.value = false
    filter.value.search_fields = ['']
    showFilter.value = false
    modelValue.value = false
  })
})

const setMockData = () => {
  items.value = globalSearchMock.data
  countUsers.value = globalSearchMock.total
}

onUnmounted(() => {
  useMittRemove('tour:globalSearch')
  useMittRemove('tour:setGlobalSearchMock')
  useMittRemove('tour:clearGlobalSearchMock')
})

const resetSearch = () => {
  filter.value.search_word = ''
  items.value = []
  pagination.value.page = 1
  loading.value = false
}

const getSearchedItems = async (more?: boolean) => {
  if (!more) loading.value = true
  const query = {
    ...pagination.value,
    ...filter.value,
  }
  delete query.updated
  if (query.search_fields.length === 1 && query.search_fields[0] === '') delete query.search_fields

  try {
    const response = await useGetGlobalSearch(query)
    if (more) {
      items.value.push(...response.data)
    } else {
      items.value = response.data
    }

    countUsers.value = response.total
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    if (!more) loading.value = false
  }
}

const onChangeTab = () => {
  pagination.value.page = 1
  if (filter.value.search_word) getSearchedItems()
}

const setLastUpdated = (item: InputItem) => {
  pagination.value.page = 1
  if (item.id === '' || item.id === filter.value.updated) {
    filter.value.updated = ''
    filter.value.updated_at_from = null
    filter.value.updated_at_to = null
  } else {
    filter.value.updated = item.id
    ;[filter.value.updated_at_from, filter.value.updated_at_to] = item.value
  }
  if (filter.value.search_word) getSearchedItems()
}

const setDisplayOnly = (item: InputItem) => {
  pagination.value.page = 1

  if (item.value === '') {
    filter.value.search_fields = ['']
    filter.value.pipeline_codes = []
  } else {
    const filterType = item.id as 'search_fields' | 'pipeline_codes'

    if (filter.value[filterType].includes(item.value)) {
      filter.value[filterType] = filter.value[filterType].filter((i) => i !== item.value)
      if (!filter.value.search_fields.length && !filter.value.pipeline_codes.length) {
        filter.value.search_fields = ['']
      }
    } else {
      filter.value.search_fields = filter.value.search_fields.filter((i) => i !== '')
      filter.value[filterType].push(item.value)
    }
  }

  if (filter.value.search_word && !isTour.value) getSearchedItems()
  if (isTour.value) {
    if (filter.value.search_fields[0] === 'notes') {
      items.value = items.value.filter((i) => i.activities.length)
      countUsers.value = items.value.length
    }
    if (filter.value.search_fields[0] === '') setMockData()
  }
}

const showMore = async () => {
  moreLoading.value = true
  pagination.value.page = pagination.value.page + 1
  await getSearchedItems(true)
  moreLoading.value = false
}
</script>

<style scoped></style>
