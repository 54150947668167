export const ARCHIVES_STAGES = {
  LOST: 'archive:lost',
  WON: 'archive:won',
}

export const POOL_STAGES = {
  VERIFIED: 'pool:verified',
  UNVERIFIED: 'pool:unverified',
}

export const STAGES = {
  NEW: 'sales:new',
  NO_ANSWER: 'sales:no-answer',
  CALL_LATER: 'sales:call-later',
  DORMANT: 'sales:dormant',
  INTERESTED: 'sales:interested',
  PROPOSAL: 'sales:proposal',
  MEETING: 'sales:meeting',
  CLOSED: 'sales:closed',
  QUALIFIED: 'sales:qualified',
}

export const CLOSED_LOST_STEPS = {
  CLOSED_IMMATURITY: 'sales:closed:immaturity',
  CLOSED_MISTAKE: 'sales:closed:mistake',
  CLOSED_NO_BUDGET: 'sales:closed:no-budget',
  CLOSED_NOT_INTERESTED: 'sales:closed:not-interested',
  CLOSED_OTHER: 'sales:closed:other',
  CLOSED_DUPLICATE: 'sales:closed:duplicate',
  CLOSED_BROKE: 'sales:closed:broke',
}

export const ARCHIVE_LOST_STEPS = {
  ARCHIVE_WON: 'archive:won',
  ARCHIVE_IMMATURITY: 'archive:immaturity',
  ARCHIVE_MISTAKE: 'archive:mistake',
  ARCHIVE_NO_BUDGET: 'archive:no-budget',
  ARCHIVE_NOT_INTERESTED: 'archive:not-interested',
  ARCHIVE_OTHER: 'archive:other',
  ARCHIVE_DUPLICATE: 'archive:duplicate',
  ARCHIVE_BROKE: 'archive:broke',
  ARCHIVE_LOST: 'archive:lost',
}

export const STEPS = {
  FRESH: 'sales:new:fresh',
  UNFROZEN: 'sales:interested:unfroze',
  HOT: 'sales:interested:hot',
  FAILED: 'sales:new:failed',
}

export const CLOSE_STEPS = {
  WON: 'sales:closed:won',
}

export const POOL_REASONS = {
  LANGUAGE_BARRIER: 'pool:language-barier',
  USER_DELETION: 'pool:after-user-deletion',
  CONFLICT_OF_INTERESTS: 'pool:conflict-of-interests',
  DUPLICATE: 'pool:duplicate',
  BROKEN: 'pool:broken',
  OTHER: 'pool:other',
}

export const ROLES = {
  AGENT: 'agent',
  MANAGER: 'sales_manager',
  HR_MANAGER: 'hr_manager',
  FINANCE_MANAGER: 'finance_manager',
  ADMIN: 'super_admin',
  MARKETER: 'marketer',
  SDR: 'sdr',
  CEO: 'ceo',
  LEAD_MANAGER: 'lead_manager',
}

export const STATUSES = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  DONT_DISTURB: 'dont_disturb',
  INACTIVE: 'inactive',
}

export const PIPELINES = {
  SALES: 'sales',
  POOL: 'pool',
  ARCHIVE: 'archive',
  QUEUE: 'queue',
  CALL_QUEUE: 'call_queue',
}

export const LEAD_STRATEGIES = {
  POOL: 'pool',
  ROTATION: 'rotation',
  AGENT: 'agent',
}

export const LEAD_ACTIVITY_STATUSES = {
  FUTURE: 'future',
  TODAY: 'today',
  OVERDUE: 'overdue',
}

export const PERMISSIONS = {
  ASSIGN_PERMISSIONS: 'assign-permissions-to-roles',
  ASSIGN_ROLES: 'assign-roles',
  DELETE_USER_PROFILES: 'delete-user-profiles',
  SET_AVAILABLE_STATUS: 'set-available-status',
  TURN_OFF_2FA: 'turn-off-2fa',
  UPDATE_USER_PROFILE: 'update-user-profile',
  EDIT_TEAM_AGENTS: 'assignremove-sales-agent-into-the-team',
  EDIT_TEAM_MANAGER: 'assign-remove-sales-manager-for-the-team',
  CREATE_NEW_TEAM: 'create-new-team',
  EDIT_DELETE_SALES_TEAM: 'editdelete-sales-team',
  MANAGE_ROTATIONS: 'create-and-edit-rotation-rules-and-boxes',
  SEE_ASSIGNED_LEADS: 'review-full-info-about-leads-assigned-to-this-user',
  SEE_CREATED_LEADS: 'review-full-info-about-lead-this-user-created',
  SEE_CONTACT_DETAILS_LEADS: 'view-user-lead-details',
  SEE_CONTACT_DETAILS_TEAM_LEADS: 'view-lead-contact-details-team-mates',
  MANAGE_QUEUE: 'has-access-to-srd-queue',
  MANAGE_CALL_QUEUE: 'can-see-call-queue',
  SEE_ALL_LEADS: 'review-full-leads-info-in-all-teams',
  SEE_TEAM_LEADS_NO_DETAILS: 'review-info-about-leads-in-the-team-except-contact-details',
  CAN_MAKE_BULK_ACTIONS_QUEUE: 'make-bulk-action-in-sdr-queue',
  CAN_MAKE_BULK_ACTIONS_PIPELINE: 'can-make-bulk-actions-on-pipeline',
  CAN_MAKE_BULK_ACTIONS_POOL: 'can-make-bulk-actions-on-pool',
  CAN_CREATE_SHADOW_LEADS: 'can-create-shadow-leads',
  CAN_SEND_LEAD_TO_QUEUE: 'send-to-sdr-queue',
  MANAGE_POOL: 'view-and-manage-entire-pool-of-leads',
  MANAGE_POOL_NO_DETAILS: 'view-and-manage-entire-pool-of-leads-without-seeing-contact-details',
  SEE_AGENT_DASHBOARD: 'personal-dashboard-for-agent-with-personal-insights',
  SEE_MANAGER_DASHBOARD: 'personal-dashboard-for-managers-with-group-insights',
  //   PRO_ANALYTICS_FINANCE: 'pro-analytics-finance',
  //   PRO_ANALYTICS_KPI_PER_ASSIGNED_TEAM: 'pro-analytics-kpi-per-assigned-team',
  //   PRO_ANALYTICS_KPIS_PER_ALL_TEAMS: 'pro-analytics-kpis-per-all-teams',
  SALES_MANAGER_ANALYTICS: 'sales-manager-analytics',
  TEAM_ANALYTICS_AGENTS: 'team-analytics-agents',
  TEAM_ANALYTICS_CALLS: 'team-analytics-calls',
  TEAM_ANALYTICS_CAMPAIGNS: 'team-analytics-campaigns',
  MANAGE_ARCHIVE: 'view-and-manage-entire-archive-of-leads',
  MANAGE_ARCHIVE_NO_DETAILS: 'view-and-manage-entire-archive-of-leads-without-seeing-contact-details',
  MANAGE_ACTIVITIES: 'manage-activities',
  CLOSE_ACTIVITIES_ON_BEHALF_OF_THE_USER: 'close-activities-on-behalf-of-the-user',
  SEE_MARKETER_LEADS_PIPELINE: 'view-marketer-leads-in-pipeline',
  SEE_MARKETER_LEADS_POOL: 'view-marketer-leads-in-pool',
  SEE_MARKETER_LEAD: 'view-lead-details-page-for-marketer-lead',
  SEE_TIMELINE_NO_DETAILS: 'view-timeline-without-contact-details',
  MANAGE_USERS_CALLS_SETTINGS: 'manage-users-calls-settings',
  VIEW_MY_DEALS: 'view-my-deals',
  VIEW_MY_TEAMS_DEALS: 'view-my-teams-deals',
  VIEW_ALL_DEALS: 'view-all-deals',
  VIEW_MY_COMMISSIONS: 'view-my-commissions',
  VIEW_MY_TEAM_COMMISSIONS: 'view-my-team-commissions',
  VIEW_ALL_COMMISSIONS: 'view-all-commissions',
  REQUEST_DEAL_INFO_APPROVAL: 'request-deal-info-approval',
  APPROVE_DEAL_INFO: 'approve-deal-info',
  REQUEST_COMMISSIONS_APPROVAL: 'request-commissions-approval',
  DEAL_INFO_AND_COMMISSIONS_APPROVAL: 'deal-info-and-commissions-approval',
  CANCEL_DEAL_WHEN_DOWN_PAYMENT_IS_RECEIVED: 'cancel-deal-when-down-payment-is-received',
  VIEW_PAYMENTS_STAGE: 'view-payments-stage',
  APPROVE_PAYOUT: 'approve-payout',
  CONFIRM_PAYOUT: 'confirm-payout',
  LEAD_MANAGER_DASHBOARD: 'personal-dashboard-for-lead-manager',
  CREATE_SHARED_ACTIVITY: 'create-shared-activity',
  SDR_DASHBOARD: 'view-personal-sdr-dashboard',
  VIEW_ASSIGN_LOGS: 'view-assignment-logs',
  VIEW_BUYER_DETAILS_ON_DEAL: 'view-buyer-details-on-deal',
  VIEW_PARTNER_DETAILS_ON_DEAL: 'view-partner-details-on-deal',
  EDIT_DEAL: 'edit-deal',
  EDIT_DEAL_TAGS: 'edit-deal-tags',
  VIEW_OWN_ARCHIVED_LEADS: 'view-own-archived-leads',
  VIEW_PROPERTY_CATALOGUE: 'property-catalogue-view',
  VIEW_HIGHLIGHTED_NOTE_IN_TIMELINE: 'view-highlighted-note-in-timeline',
  VIEW_MY_QUALIFIED_LEADS: 'view_my_qualified',
  VIEW_ALL_QUALIFIED_LEADS: 'view_all_qualified',
  VIEW_PARTNER_NAME_IN_DEAL_SECTION: 'view_partner_name_in_deal_section',
  CAN_SEE_AND_FILTER_QUALIFIED_FIELDS: 'see_and_filter_by_qualified_by_on',
  CAN_FILTER_BY_TEAM: 'filter_by_teams',
  SEE_MANAGER_VIEW_IN_CALL_LOG: 'can-see-manager-view-in-the-call-log',
  WHATSAPP_VIEW_ALL_CHATS_HISTORY: 'whatsapp-view-all-chats-history-on-lead-page',
  VIEW_FINANCE_INFORMATION_IN_THE_DEAL_TABLE: 'view_finance_information_in_the_deal_table',
  CAN_EXPORT_DEAL_AND_LEAD_TABLES: 'can-export',
  CAN_EDIT_DEAL_ON_ANY_STAGE: 'can-edit-deal-on-any-stage',
  CAN_SEE_STATUS_OF_INFORMATION_ON_DEAL_PAGE: 'can-see-status-of-information-on-deal-page',
  PROPERTY_CATALOGUE_VIEW_EXCLUSIVE_UNITS: 'property-catalogue-view-exclusive-units',
  CAN_SEE_ALL_LEADS_TAB: 'view-all-leads',
  CAN_SET_DEAL_VERIFICATION_STATUS: 'can-set-verification-status-on-deal',
  CAN_EDIT_ONLY_BOOKING_DATE_ON_THE_DEAL: 'can-edit-only-booking-date-on-the-deal',
  CAN_SEE_CLIENT_BASE: 'view-client-base',
  CAN_EDIT_LEAD_SOURCE: 'edit-lead-source-details',
  CAN_EDIT_LEAD_TAGS: 'edit-lead-tags',
  CAN_SEE_GENERATE_CAMPAIGN_LINK: 'can-open-generate-campaign-link',
  CAN_GENERATE_PAYMENT_LINK_FOR_EXCLUSIVE_UNIT: 'can-generate-payment-link-for-exclusive-unit',
  CAN_VIEW_FULL_PROPERTY_UNIT_INFO: 'can-view-full-property-unit-info',
  CAN_VIEW_PAYMENT_TOKENS: 'can-view-payment-tokens',
  CAN_CANCEL_HOLD_FOR_EXCLUSIVE_UNIT: 'can-cancel-hold-for-exclusive-unit',
}

export const PARAMETERS_NAMES_MAP = new Map<string, string>([
  ['utm_campaign', 'UTM Campaign'],
  ['utm_content', 'UTM Content'],
  ['utm_source', 'UTM Source'],
  ['utm_term', 'UTM Term'],
  ['source', 'Lead Source'],
  ['utm_medium', 'UTM Medium'],
  ['language_iso', 'Language'],
  ['country_iso', 'Country'],
  ['agent_id', 'User'],
  ['additional_info1', 'Additional info 1'],
  ['additional_info2', 'Additional info 2'],
  ['additional_info3', 'Additional info 3'],
  ['additional_info4', 'Additional info 4'],
  ['additional_info5', 'Additional info 5'],
  ['additional_info6', 'Additional info 6'],
  ['additional_info7', 'Additional info 7'],
  ['additional_info8', 'Additional info 8'],
  ['additional_info9', 'Additional info 9'],
])
export const ACTIONS_NAMES_MAP = new Map<string, string>([
  ['language_id', 'Language'],
  ['country_id', 'Country'],
  ['phone_country_id', 'Phone code'],
  ['timezone_id', 'Timezone'],
])

export const ROTATION_TYPES = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
  POOL: 'pool',
  USER: 'user',
}

export const CALENDAR_VIEWS = {
  DAY: 'timeGridDay',
  WEEK: 'timeGridWeek',
  MONTH: 'dayGridMonth',
}

export const CALENDAR_ACTIVITY_TYPES = {
  CALL: 'calendar_call',
  MEETING: 'calendar_meeting',
  TASK: 'calendar_task',
  ALL_DAY: 'calendar_all_day',
}

type TypesColors = {
  background: string
  hovered?: string
  pressed?: string
}

export const CALENDAR_ACTIVITY_TYPES_COLORS_MAP = new Map<string | undefined, TypesColors>([
  ['calendar_call', { background: '#E9FAF5', hovered: '#D3F5EB', pressed: '#BCEFE0' }],
  ['calendar_meeting', { background: '#EBEEFB', hovered: '#D7DDF6', pressed: '#C4CDF2' }],
  ['calendar_task', { background: '#FFF8F1', hovered: '#FFF1E3', pressed: '#FFEAD5' }],
  ['calendar_all_day', { background: '#FFF8F1', hovered: '#FFF1E3', pressed: '#FFEAD5' }],
  [undefined, { background: '#F3F3F3' }],
])

export const CALENDAR_ACTIVITY_STATUSES = {
  TO_DO: 'to_do',
  DONE: 'done',
  OVERDUE: 'overdue',
}

export const CALENDAR_ACTIVITY_MEETING_OUTCOMES = {
  PENDING: 'pending',
  COMPLETED: 'completed',
}

type StatusesColors = {
  background: string
  text: string
}

export const CALENDAR_ACTIVITY_STATUSES_COLORS_CLASSES_MAP = new Map<string, StatusesColors>([
  ['overdue', { background: 'bg-secondary-20', text: 'text-error-100' }],
  ['to_do', { background: 'bg-primary-10', text: 'text-primary-100' }],
  ['done', { background: 'bg-additional-2-20', text: 'text-additional-2-120' }],
  ['autoclosed', { background: 'bg-black-30', text: 'text-black-100' }],
])

export const ENVS = {
  DEV: 'dev',
  STAGING: 'STAGING',
  PROD: 'prod',
}

export const TAGS = {
  HIGH_QUALITY_LEAD: 'hq-lead',
  FRESH_LEAD: 'fresh-lead',
  UNFROZEN_LEAD: 'unfrozen-lead',
  HOT_LEAD: 'hot-lead',
  SHADOW_ORIGINAL_LEAD: 'shadow-lead-original',
  SHADOW_COPY_LEAD: 'shadow-lead-copy',
  FAILED: 'failed-lead',
  IN_UAE: 'in-uae',
  REFERRAL: 'referral',
  ENRICHED: 'enriched-lead',
  FBYB: 'fbyb',
  DIC: 'dic-member',
  WHATSAPP: 'wa-lead',
}

export const CUSTOM_EVENTS = {
  REFRESH_LEAD_HISTORY: 'refresh-lead-history',
  REFRESH_LEAD_DATA: 'refresh-lead-data',
  REFRESH_DEAL_DATA: 'refresh-deal-data',
}

export const HIDDEN = 'hidden'
export const DEFAULT_LIMIT_LENGTH = 25

// TODO: rename FINANCE_DEALS_STAGES -> FINANCE_DEAL_STAGES
export const FINANCE_DEALS_STAGES = {
  PAYMENTS: 'payments',
  DOWN_PAYMENT: 'down_payment',
  SPA: 'spa',
  NEW_DEAL: 'new_deal',
  CLOSED: 'closed',
  FINANCE_REVIEW: 'finance_review',
}

export const FINANCE_DEAL_STAGES_COLORS_MAP = new Map([
  [FINANCE_DEALS_STAGES.NEW_DEAL, '#FFDDD2'],
  [FINANCE_DEALS_STAGES.FINANCE_REVIEW, '#FFDCB8'],
  [FINANCE_DEALS_STAGES.DOWN_PAYMENT, '#FBEEBD'],
  [FINANCE_DEALS_STAGES.SPA, '#BCEFE0'],
  [FINANCE_DEALS_STAGES.PAYMENTS, '#C4CDF2'],
  [FINANCE_DEALS_STAGES.CLOSED, '#C4CDF2'],
])

export const FINANCE_DEALS_STATUSES = {
  NEW_DEAL_NEW: 'new_deal_new',
  NEW_DEAL_SM_APPROVAL: 'new_deal_sm_approval',
  NEW_DEAL_FINANCE_REVIEW: 'new_deal_finance_review',
  NEW_DEAL_CEO_APPROVAL: 'new_deal_ceo_approval',
  SPA_PENDING: 'spa_pending',
  SPA_SENT: 'spa_sent',
  SPA_SIGNED: 'spa_signed',
  DOWN_PAYMENT_PENDING: 'down_payment_pending',
  DOWN_PAYMENT_PARTIAL: 'down_payment_partial',
  DOWN_PAYMENT_RECEIVED: 'down_payment_received',
  PAYMENT_CEO_APPROVAL: 'payment_ceo_approval',
  PAYMENT_INVOICE_PENDING: 'payment_invoice_pending',
  PAYMENT_INVOICE_ISSUED: 'payment_invoice_issued',
  PAYMENT_RECEIVED: 'payment_received',
  PAYMENT_PROCESSING: 'payment_processing',
  PAYMENT_PARTIAL: 'payment_partial',
  CLOSED_FINALIZED: 'closed_finalized',
  CLOSED_CANCELED: 'closed_canceled',
  CLOSED_ARCHIVED: 'closed_archived',
}

export const FINANCE_FILE_TYPES = {
  RESERVATION_FORM: 'reservation_form',
  PASSPORT_COPY: 'passport_copy',
  DOWN_PAYMENT_RECEIPT: 'down_payment_receipt',
  PAYMENT_INVOICE: 'payment_invoice',
  INCOMING_PAYMENT: 'incoming_payment',
  OTHER: 'other',
  CONTRACT_FORM: 'contract_form',
}

export const FINANCE_COMMISSION_TYPES = {
  KICKBACK_PAYMENT: 'kickback_payment',
  COMMISSION_ADJUSTMENT: 'commission_adjustment',
  COMMISSION_ADJUSTMENT_GROUP: 'commission_adjustment_group',
  COMMISSION_ADJUSTMENT_TOTAL: 'commission_adjustment_total',
  PARTNER: 'partner',
  PARTNER_MANAGER: 'partner_manager',
  BROKERAGE: 'brokerage',
  SM: 'sm',
  AGENT: 'agent',
  AGENT_SPLIT: 'agent_split',
  REVENUE: 'revenue',
}

export const FINANCE_COMMISSIONS_STATUSES = {
  NEW: 'new',
  WAITING: 'waiting',
  APPROVED: 'approved',
  PAYOUT_COMPLETED: 'payout_completed',
}

export const FINANCE_AGREEMENT_TYPES = {
  SENT_TO_CLIENT: 'sent_to_client',
  SIGNED_BY_CLIENT: 'signed_by_client',
  RECEIVED_BY_DEVELOPER: 'received_by_developer',
}

export const FINANCE_AGREEMENT_STATUSES = {
  NEW: 'new',
  APPROVED: 'approved',
}

export const FINANCE_PAYMENT_INVOICE_STATUSES = {
  NEW: 'new',
  CONFIRMED: 'confirmed',
}

export const FINANCE_DEAL_ACTION_STATUSES = {
  APPROVED: 'approved',
  DECLINED: 'declined',
}
export const FINANCE_DEAL_ACTION_TYPES = {
  SM_APPROVAL: 'new_deal_info_sm_approval',
  CEO_APPROVAL: 'new_deal_commissions_ceo_approval',
}

export const FINANCE_DEAL_VERIFICATION_STATUSES = {
  VERIFIED: 'verified',
  NEED_VERIFICATION: 'need_verification',
  WAIT_FOR_ACTION: 'wait_for_action',
}

export const DEFAULT_DEBOUNCE_INPUT_TIME = 500

export const CALL_LOGS_STATUSES = {
  SUCCESS: 'successful_call',
  MISSED: 'missed',
  VOICEMAIL: 'voice_mail',
  CALL_ISSUE: 'call_issue',
  NO_ANSWER: 'no_answer',
}

export const CALL_LOGS_DIRECTIONS = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
}

export enum TOURS_IDS {
  CALL_LOG = 1,
  GLOBAL_SEARCH = 2,
  ACTIVITY_CREATION_TYPES = 3,
  ACTIVITY_MEETING_CREATION = 4,
  SDR = 5,
  NEWLY_ADDED = 6,
  WELCOME_ABOARD = 7,
  PROPERTIES_OFFERS = 8,
  PIN_NOTE = 9,
  QUICK_FILTERS = 10,
  NAVIGATION_MENU = 11,
}

export enum TOURS_TYPES {
  NEW_FEATURE = 'new-feature',
  UPDATE = 'update',
  INIT_USER = 'init-user',
}

export const ROTATIONS_QUEUE_VALUE = 'queue'

// the key must match the store getter and setter
// e.g PROPERTIES_BUILDING_STATUSES = getPropertiesBuildingStatuses, setPropertiesBuildingStatuses
export enum DICTIONARIES {
  PROPERTIES_BUILDING_STATUSES = 'building_status',
  PROPERTIES_LOCATIONS = 'location',
  PROPERTIES_ROOMS_COUNT = 'rooms_count',
  PROPERTIES_SALE_STATUSES = 'sale_status',
  PROPERTIES_TYPES = 'property_type',
  PROPERTIES_AMENITIES = 'property_amenity',
  DEVELOPER_NAMES = 'developer_names',
  LOCATION = 'location',
  EXCLUSIVE_UNIT_STATUSES = 'exclusive_unit_statuses',
  EXCLUSIVE_UNIT_TYPES = 'exclusive_unit_types',
  PROPERTIES = 'properties',
  EMIRATE_NAMES = 'emirate_names',
  PAYMENT_TOKEN_STATUSES = 'payment_token_statuses',
  PAYMENT_TOKEN_METHODS = 'payment_token_methods',
  PAYMENT_TOKEN_TYPES = 'payment_token_types',
}

export const PROPERTIES_SALE_STATUSES = {
  ANNOUNCEMENT: 'announcement',
  LAUNCH: 'launch',
  ON_SALE: 'on_sale',
  PENDING: 'pending',
  PRE_SALE: 'presale(_e_o_i)',
  SOLD_OUT: 'sold_out',
}

export const FINANCE_CONTACT_TYPES = {
  PRIMARY: 'primary',
  ADDITIONAL: 'additional',
}

export const FINANCE_COMMISSIONS_GROUPS = {
  PARTNERS: 'partners',
  MAIN: 'main',
  ADDITIONAL: 'additional',
}

export const FINANCE_COMMISSIONS_GROUP_STATUSES = {
  NEW: 'new',
  WAITING: 'waiting',
  APPROVED: 'approved',
  PAYOUT_COMPLETED: 'payout_completed',
}

export enum FINANCE_COMMISSIONS_GROUP_ID {
  MAIN = 1,
  ADDITIONAL = 2,
  PARTNERS = 3,
}
export enum FINANCE_COMMISSIONS_GROUP_STATUS_ID {
  NEW = 1,
  WAITING = 2,
  APPROVED = 3,
}

export const PROPERTIES_ROOMS_MAP: { [key: string]: string } = {
  rooms_studio: 'Studio',
  rooms_1: '1 BR',
  rooms_2: '2 BR',
  rooms_3: '3 BR',
  rooms_4: '4 BR',
  rooms_5: '5 BR',
  rooms_6: '6 BR',
}

export enum PARTNER_TYPES {
  ASSIGNED = 'assigned',
  ADDITIONAL = 'additional',
}

export enum LEAD_PHONE_TYPES_CODES {
  PRIMARY = 'primary',
  VIA_PARTNER = 'via_partner',
  VIA_REPRESENTATIVE = 'via_representative',
}

export enum RELEASE_COMPONENTS_CODES {
  FE_CRM = 'fe_crm',
  FE_CMS = 'fe_cms',
  FE_PARTNERS_PORTAL = 'fe_partners_portal',
  BE_CRM = 'be_crm',
  BE_PROPERTY_CATALOGUE = 'be_property_catalogue',
  BE_WA = 'be_wa',
}

export enum RELEASE_COMPONENTS_STATUS_CODES {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  DEPLOYED = 'deployed',
  FAILED = 'failed',
}

export enum EXCLUSIVE_UNIT_STATUS_CODES {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not_available',
  HOLD = 'hold',
}

export enum PROPERTIES_TAGS_CODES {
  PROPERTY_EXCLUSIVE = 'property-exclusive',
}

export enum MEETING_OUTCOME_RESOLUTION_CODES {
  SUCCESSFUL = 'successful',
  DIDNT_SHOW_UP = 'didnt_show_up',
  RESCHEDULED = 're_scheduled',
  AUTOCLOSED = 'autoclosed',
}

export const LOCAL_NUMBER_TYPES = {
  PERSONAL: 1,
  COMPANY: 2,
}

export enum PAYMENT_TOKEN_CODES {
  LINK = 'link',
  MANUAL = 'manual',
}

export enum PAYMENT_TOKEN_STATUSES_CODES {
  PAYMENT_PENDING = 'payment_pending',
  VERIFICATION_PENDING = 'verification_pending',
}

export const LIST_OF_HOURS = Array.from({ length: 24 }, (_, i) => {
  const hour = i + 1
  return { value: `${hour}h`, text: `${hour} hour${hour > 1 ? 's' : ''}` }
})

export const PAYMENT_TOKEN_HOLD_TIME = 'payment_token_hold_time'

export enum LEAD_SOURCE_IDS {
  DIGITAL = 1,
  OWN = 2,
  PARTNER = 3,
}

export const SETTING_CODES = {
  // admin-settings-telephony-settings
  SUPPORT_PHONE: 'support_phone',
  SMS_PHONE: 'activity_sms_notification_default_number',
  BRIGHT_CALL: 'bright_call_enabled_checkbox',

  // admin-settings-leads-settings
  WORKING_DAYS: 'working_days',
  WORKING_HOURS_FROM: 'working_hours_from',
  WORKING_HOURS_TO: 'working_hours_to',
  WORKING_TIMEZONE: 'working_timezone_id',
  DELAY: 'duplicate_lead_delay',
  MAX_TIME: 'lead_is_duplicate_max_time',
  HIDE_PHONE: 'pbx_availability',

  // admin-settings-leads-sdr
  LEAD_QUEUE_TIME_TO_MOVE_TO_ROTATION_MINUTES: 'lead_queue_time_to_move_to_rotation_minutes',
  LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS_CHECKBOX: 'lead_queue_time_to_return_to_queue_seconds_checkbox',
  LEAD_QUEUE_TIME_TO_RETURN_TO_QUEUE_SECONDS: 'lead_queue_time_to_return_to_queue_seconds',
  SDR_REPORT_EMAILS: 'sdr_report_mails',

  // admin-settings-notifications-settings
  ASSIGNED_DELAY: 'assigned_lead_notification_delay',
  CALENDAR_CHECKBOX: 'activity_push_notification_min_before_checkbox',
  CALENDAR_DELAY: 'activity_push_notification_min_before',
  SMS_CHECKBOX: 'activity_created_sms_notification_text_checkbox',
  SMS_TEMPLATE_WITHOUT_DELAY: 'activity_created_sms_notification_text',
  SMS_CHECKBOX_2N: 'activity_sms_notification_min_before_checkbox',
  SMS_DELAY: 'activity_sms_notification_min_before',
  SMS_TEMPLATE_REMINDER: 'activity_coming_sms_notification_text',

  SMS_PAYMENT_TOKEN_HOLD_TIME: 'payment_token_hold_time',
}

export enum PAYMENT_TOKEN_STATUS_CODES {
  PAYMENT_PENDING = 'payment_pending',
  VERIFICATION_PENDING = 'verification_pending',
  CANCELLED = 'canceled',
  EXPIRED = 'expired',
  PAID = 'paid',
}
